@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.react-contextmenu {
    background-color:$color-lightest;
    border: 1px solid rgba($color-darkest, .15);
    border-radius: 2px;
    color: $color-darkest;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 0.25s ease !important;

    &--visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 9999;
    }

    .react-contextmenu-item {
        background: 0 0;
        border: 0;
        color: $color-darkest;
        cursor: pointer;
        font-weight: 400;
        line-height: 1.5;
        padding: 3px 20px;
        text-align: inherit;
        white-space: nowrap;

        &--active,
        &--selected {
            color: $color-lightest;
            background-color: getColor($registration, 600);
            border-color: getColor($registration, 600);
            text-decoration: none;
        }

        &--disabled,
        &--disabled:hover {
            background-color: transparent;
            border-color: rgba($color-darkest, .15);
            color: getColor($gray, 500);
        }

        &--divider {
            border-bottom: 1px solid rgba($color-darkest, .15);
            cursor: inherit;
            margin-bottom: 3px;
            padding: 2px 0;

            &:hover {
                background-color: transparent;
                border-color: rgba($color-darkest, .15);
            }
        }

        &.react-contextmenu-submenu {
            padding: 0;

            & > .react-contextmenu-item {
                &:after {
                    content: "▶";
                    display: inline-block;
                    position: absolute;
                    right: 7px;
                }
            }
        }
    }
}

.react-contextmenu--bare {
    padding: 0;
    border: none;
    background-color: transparent;

    .react-contextmenu-item {
        padding: 0;
        background-color: transparent;

        &--active,
        &--selected {
            color: inherit;
            background-color: transparent;
            border: none;
            text-decoration: none;
        }
    }
}
