@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.user-account-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
            "nav"
            "header"
            "contents";
    grid-row-gap: $spacer;
    grid-column-gap: $spacer * 3;
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;

    &__navigation {
        justify-self: center;
        grid-area: nav;
    }

    &__header {
        grid-area: header;
    }

    &__contents {
        grid-area: contents;
    }
}
