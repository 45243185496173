@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.recover-password-page {
    &__pre-recover-block,
    &__post-recover-block {
        text-align: center;
        color: getColor($registration, 600);
    }

    &__description {
        margin-bottom: $spacer;
    }

    &__help {
        margin-top: $spacer;
        text-align: center;
        color: getColor($registration, 600);

        a {
            color: getColor($registration, 600);

            &:hover {
                color: getColor($registration, 800);
            }
        }
    }
}
