@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.stream-score {
    max-width: 990px;
    margin: 0 auto;

    &__header {
    }

    &__title {
        color: getColor($registration, 400);
        font-size: 44px;
        margin: 0;
        font-weight: 600;
    }

    &__bar-container {
        display: flex;
        flex-direction: column;
        height: 71px;
        justify-content: space-between;
    }

    &__scores {
        position: relative;
    }

    &__bar {
        content: "";
        background: linear-gradient(176.43deg, getColor($registration, 50) 0%, getColor($registration, 600) 25.19%, getColor($secondary, 900) 48.36%);
        height: 18px;
        border-radius: 18px;
        display: block;
    }

    &__min,
    &__max {
        position: absolute;
        color: getColor($registration, 400);
        font-size: 38px;
    }

    &__min {
        left: 0;
    }

    &__max {
        right: 0;
    }

    &__current {
        position: absolute;
        display: flex;
        flex-direction: column;
        color: getColor($registration, 400);
        font-style: italic;
        font-size: 60px;
        font-weight: 600;
        transform: translateX(-30px) translateY(-22px);
        align-items: center;

        &::after {
            content: "";
            width: 27px;
            height: 27px;
            border-radius: 50%;
            background-color: $color-lightest;
        }
    }

    &__footer {
        padding-top: $spacer / 2;
        padding-bottom: $spacer / 2;
        display: grid;
        grid-gap: $spacer;
        grid-template-columns: 1fr 2fr;
    }

    &__benefit {
        font-size: 18px;
        font-weight: 200;
        color: getColor($registration, 400);
        display: flex;
        flex-direction: column;
    }

    &__benefit-value {
        font-size: 20px;
        color: getColor($registration, 400);
        font-weight: 600;
    }

    &__actions {
    }
}
