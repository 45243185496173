@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.change-password-page {
    &__pre-change-block,
    &__post-change-block {
        text-align: center;
        color: getColor($registration, 600);
    }
}
