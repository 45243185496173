@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.platform-button {
    $pf: &;
    background-color: getColor($registration, 50);
    display: inline-flex;
    align-items: center;
    padding: $spacer / 3;
    padding-left: $spacer;
    position: relative;
    transition: all 0.15s ease;
    min-height: 55px;
    min-width: 180px;

    &::before {
        content: "";
        width: 7px;
        background-color: getColor($registration, 600);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        transition: all 0.15s ease;
    }

    &--is-checked {
        color: $color-lightest;
        background-color: getColor($registration, 600);

        &::before {
            background-color: getColor($secondary, 900);
        }
    }

    &__tick {
        position: absolute;
        right: $spacer / 3;
    }

    &--is-bare {
        &::before {
            content: none;
        }

        #{$pf}__tick {
            display: none;
        }
    }
}
