//* ============ COLORS =========== */
$primary: (
    900: #00735D,
    800: #00917E,
    700: #00A28E,
    600: #00B4A1,
    500: #00C3B1,
    400: #00CFC0,
    300: #00DBD0,
    200: #42E8E0,
    100: #9FF1EC,
    50: #DAF9F9
);

$primary-light: (
    900: #0E8F89,
    800: #00B5B7,
    700: #00CBCF,
    600: #00E2EB,
    500: #00F3FF,
    400: #1CF8FE,
    300: #4AFCFF,
    200: #83FFFF,
    100: #B7FFFD,
    50: #E2FFFE
);

$primary-dark: (
    900: #004B3E,
    800: #00675B,
    700: #00776B,
    600: #00877B,
    500: #009489,
    400: #00A59C,
    300: #00B5AE,
    200: #6BCAC6,
    100: #A8DFDC,
    50: #DCF2F2
);

$secondary: (
    900: #021967,
    800: #2052BC,
    700: #1E63CF,
    600: #1A75E3,
    500: #1383F1,
    400: #2F95F7,
    300: #50A7FB,
    200: #83C0FF,
    100: #B5D8FF,
    50: #E0F0FF
);

$secondary-dark: (
    900: #004691,
    800: #0063B0,
    700: #0974C2,
    600: #1685D4,
    500: #1F93E1,
    400: #42A2E5,
    300: #64B2E8,
    200: #8FC8EF,
    100: #BADDF5,
    50: #E3F1FA
);

$registration: (
    900: #324F9C,
    800: #3C6DBD,
    700: #427ECF,
    600: #4A90E2,
    500: #509EF0,
    400: #61ACF3,
    300: #79BBF5,
    200: #9CCFF9,
    100: #C1E1FB,
    50: #E5F3FD
);

$gray: (
    900: #2B2B2B,
    800: #4D4D4D,
    700: #6C6C6C,
    600: #818181,
    500: #ABABAB,
    400: #DEDEDE,
    300: #F0F0F0,
    200: #F5F5F5,
    100: #FAFAFA,
    50: #FFFFFF
);

$color-primary: map_get($primary, 200);
$color-primary-light: map_get($primary-light, 200);
$color-primary-dark: map_get($primary-dark, 300);
$color-secondary: map_get($secondary, 200);
$color-secondary-dark: map_get($secondary-dark, 800);
$color-registration: map_get($registration, 600);
$color-lightest: map_get($gray, 50);
$color-darkest: map_get($gray, 900);
$text-main-color: $color-darkest;
//* ============ END COLORS =========== */

//* ============ FONT =========== */
$main-font: Helvetica, Arial, sans-serif;
//* ============ END FONT =========== */

//* ============ SPACERS =========== */
$spacer: 30 * 1px;
//* ============ END SPACERS =========== */
