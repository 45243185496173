@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.login-management-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(118.59% 118.59% at -6.3% 100%, #07E6F3 0%, #3F65D2 44.92%, #102265 99.98%, #1F0458 100%);
    height: 100%;
    color: getColor($secondary, 800);

    &__main {
        padding-left: $spacer;
        padding-right: $spacer;
        max-width: 550px;
        min-width: 360px;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        background-color: $color-lightest;
    }

    .main-title {
        color: getColor($registration, 600);
        font-size: 36px;
        text-align: center;
        margin-bottom: $spacer;
    }
}
