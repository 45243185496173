@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.payments-page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
            "nav nav"
            "header header"
            "grand-total sub-totals"
            "last-payments chart";
    grid-row-gap: $spacer;
    grid-column-gap: $spacer * 3;
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;

    &__navigation {
        justify-self: center;
        grid-area: nav;
    }

    &__header {
        grid-area: header;
    }

    &__grand-total {
        grid-area: grand-total;
    }

    &__sub-totals {
        grid-area: sub-totals;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $spacer * 2;
    }

    &__last-payments {
        grid-area: last-payments;
    }

    &__chart {
        grid-area: chart;
        margin-top: $spacer * 2;
    }
}
