@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.date-chooser {
    display: flex;
    align-items: center;
    user-select: none;
    margin-top: $spacer / 2;
    margin-bottom: $spacer / 2;

    &__item {
        color: getColor($registration, 600);
        font-size: 18px;
        display: flex;
        align-items: center;
        font-weight: 200;

        svg {
            margin-left: $spacer / 3;
        }

        &--is-chosen {
            font-weight: 600;
        }

        &::after {
            content: "|";
            font-weight: 400;
            margin-left: $spacer / 3;
            margin-right: $spacer / 3;
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }
}
