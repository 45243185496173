@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.button {
    $b: &;
    font-size: 18px;
    background-color: transparent;
    appearance: none;
    color: getColor($registration, 600);
    border: 1px solid getColor($registration, 600);
    padding: 10px 15px;
    display: inline-block;

    &:hover {
        color: $color-lightest;
        background-color: getColor($registration, 600);
    }

    &--primary {
        color: getColor($primary, 200);
        border-color: getColor($primary, 200);
        font-size: 17px;

        &:hover {
            color: getColor($secondary, 900);
            background-color: getColor($primary, 200);
        }
    }

    &--secondary {
        border-color: getColor($secondary, 800);
        color: getColor($secondary, 800);

        &:hover {
            background-color: getColor($secondary, 800);
        }
    }

    &--light {
        border-color: getColor($registration, 50);
        color: getColor($registration, 50);

        &:hover {
            color: getColor($secondary, 900);
            background-color: getColor($registration, 50);
        }
    }

    &--dark {
        border-color: getColor($secondary, 900);
        color: getColor($secondary, 900);

        &:hover {
            background-color: getColor($secondary, 900);
        }
    }

    &--small {
        padding: 5px 15px;
    }

    &--solid {
        color: $color-lightest;
        background-color: getColor($registration, 600);
        border: 1px solid getColor($registration, 600);

        &:hover {
            background-color: darken(getColor($registration, 600), 15);
            border-color: darken(getColor($registration, 600), 15);
        }

        &#{$b}--light {
            background-color: getColor($registration, 50) !important;
            border-color: getColor($registration, 50) !important;
            color: getColor($secondary, 900) !important;

            &:hover {
                background-color: darken(getColor($registration, 50), 15) !important;
                border-color: darken(getColor($registration, 50), 15) !important;
            }
        }

        &#{$b}--dark {
            background-color: getColor($secondary, 900) !important;
            border-color: getColor($secondary, 900) !important;
            color: $color-lightest !important;

            &:hover {
                background-color: darken(getColor($secondary, 900), 15) !important;
                border-color: darken(getColor($secondary, 900), 15) !important;
            }
        }
    }

    &--block {
        width: 100%;
    }

    &--gradient {
        background: radial-gradient(226.09% 226.09% at -6.3% 100%, #07E6F3 0%, #3F65D2 44.92%, #102265 99.98%, #1F0458 100%) !important;
        border: none !important;
        color: $color-lightest;

        &:hover {
            background: radial-gradient(226.09% 226.09% at -6.3% 100%, #07d7e4 0%, #3e62c3 44.92%, #102265 99.98%, #1F0458 100%) !important;
        }
    }

    & + & {
        margin-left: $spacer / 2;
    }

    &[disabled] {
        background: getColor($gray, 500) !important;
        border-color: getColor($gray, 500) !important;
        opacity: 0.8;
        pointer-events: none;

        &:focus {
            outline: none;
        }
    }
}
