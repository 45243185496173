html {
    box-sizing: border-box;
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html,
body,
#root {
    height: 100%;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    letter-spacing: -0.03em;
}

a,
button {
    transition: all 0.15s ease;
}
