@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.sub-total-item {
    display: flex;
    flex-direction: column;
    border-left: 4px solid getColor($registration, 600);

    &__header {
        padding-left: $spacer / 1.5;
        padding-right: $spacer / 2;
        margin-bottom: $spacer / 3;
    }

    &__contents {
        padding-left: $spacer / 1.5;
        padding-right: $spacer / 2;
    }

    &__title {
        font-weight: 400;
        font-size: 16px;
        color: getColor($registration, 600);
    }

    &__value {
        font-size: 29px;
        font-weight: 200;
        color: getColor($secondary, 900);
    }

    &__description {
        font-size: 12px;
        color: getColor($secondary, 700);
        margin-top: $spacer / 3;
    }
}
