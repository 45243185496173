@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.my-stats-page {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
            "nav nav nav nav"
            "header header header header"
            "col1 col1 col2 col3";
    grid-gap: $spacer;
    grid-column-gap: $spacer * 2;
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;

    &__navigation {
        justify-self: center;
        grid-area: nav;
    }

    &__header {
        grid-area: header;
        margin-bottom: $spacer * 2;
    }

    &__col1 {
        grid-area: col1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: $spacer;
        padding-right: $spacer;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            right: -15px;
            top: -20px;
            bottom: -20px;
            width: 3px;
            background-color: getColor($registration, 600);
        }
    }

    &__col2 {
        grid-area: col2;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $spacer;
    }

    &__col3 {
        grid-area: col3;
    }

    &__platforms {
        display: flex;
        align-items: center;
    }
}
