@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.check-overlay-card {
    &__header {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        padding-bottom: $spacer / 2;
    }

    &__back {
        display: flex;
        align-items: center;
        color: getColor($registration, 400);
        font-size: 17px;

        svg {
            margin-right: $spacer / 4;
            font-size: 24px;
        }
    }

    &__platforms,
    &__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $spacer;
    }
}
