@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.table {
    $t: &;
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            th {
                font-size: 14px;
                padding: $spacer / 3;
                text-align: left;
                color: getColor($registration, 600);
                font-weight: 400;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 0;
                text-align: left;

                &:first-child {
                    #{$t}__cell {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                }

                &:last-child {
                    #{$t}__cell {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }

    &__cell {
        background-color: getColor($secondary, 900);
        color: $color-lightest;
        padding: $spacer / 3;
        margin-bottom: $spacer / 4;
        display: flex;
        align-items: center;
        min-height: 55px;
        font-size: 13px;
    }

    &__row {
        &--is-featured {
            #{$t}__cell {
                background-color: getColor($registration, 600);
            }
        }
    }

    &__sorting {
        font-size: 16px;
        padding-left: $spacer / 4;
    }
}
