@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.card {
    $c: &;
    border-radius: 25px;
    border: 2px solid getColor($registration, 600);
    display: flex;
    flex-direction: column;
    height: 100%;

    &--primary {

    }

    &--secondary {
        border-color: getColor($secondary, 800);

        #{$c}__title {
            border-color: getColor($secondary, 800);
            color: getColor($secondary, 800);
        }

        #{$c}__contents {
            color: getColor($secondary, 800);
        }
    }

    &--dark {
        border-color: getColor($secondary, 900);

        #{$c}__title {
            border-color: getColor($secondary, 900);
            color: getColor($secondary, 900);
        }

        #{$c}__contents {
            color: getColor($secondary, 900);
        }
    }

    &__header {

    }

    &__title {
        border-bottom: 4px solid getColor($registration, 600);
        color: getColor($registration, 600);
        padding: $spacer;
        font-size: 32px;
        font-weight: 600;
    }

    &__contents {
        padding: $spacer;
        color: getColor($registration, 600);
    }
}
