@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.modal {
    padding: 0;
    max-height: 95vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 2px;
    min-width: 400px;

    &--size-eager {
        max-width: initial;
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
    }

    &--size-jumbo {
        max-width: initial;
        width: 90vw;
    }

    &--size-big {
        width: 75vw;
    }

    &--size-small {
        width: 40vw;
    }

    &--size-xs {
        width: 20vw;
    }

    &__body {

    }

    &__header {
        border-bottom: 1px solid getColor($gray, 400);
        padding: $spacer / 2;
        height: 50px;
    }

    &__title {
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__contents {
        padding: $spacer $spacer / 2;
    }

    &__footer {
        border-top: 1px solid getColor($gray, 400);
        padding: $spacer / 2;

        &:empty {
            display: none;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__close-button {
        &:hover,
        &:focus {
            outline: none;

            svg {
                fill: getColor($registration, 600);
            }
        }
    }

    @media (max-width: 992px) {
        width: 95vw;
    }
}
