@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.login-page {
    color: getColor($registration, 600);
    font-size: 16px;

    a {
        color: getColor($registration, 600);

        &:hover {
            color: getColor($registration, 800);
        }
    }

    &__recover-password {
        margin-top: $spacer;
        text-align: right;
        display: block;
    }

    &__sign-up {
        display: block;
        margin-top: $spacer * 2;
        text-align: center;
    }

    &__wrong-login {
        /* TODO: Definir estados semánticos: disabled, success, info, warning, danger */
        color: red;
        margin-top: $spacer / 2;
    }
}
