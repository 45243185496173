@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background-color: $color-lightest;
    color: getColor($secondary, 900);
}
