@import "src/assets/scss/variables";

.user-widget {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $color-lightest;
    border-top: 2px solid $color-lightest;
    border-bottom: 2px solid $color-lightest;
    padding: $spacer / 4;
    margin-top: $spacer;

    &__avatar {
        border-radius: 50%;
        width: 39px;
        height: 39px;
        margin-right: $spacer / 4;
    }
}
