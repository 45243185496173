@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.admin-layout {
    display: flex;
    background: radial-gradient(118.59% 118.59% at -6.3% 100%, #07E6F3 0%, #3F65D2 44.92%, #102265 99.98%, #1F0458 100%);
    height: 100%;
    color: getColor($secondary, 800);

    &__main {
        flex: 2;
        background-color: #fff;
    }

    &__sidebar {
        flex-basis: 225px;
    }
}
