@import "src/assets/scss/variables";

.tick-circle {
    width: 28px;
    height: 28px;
    background-color: $color-lightest;
    border-radius: 28px;

    &--is-checked {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            background: url(./tick.svg) no-repeat;
            width: 19px;
            height: 23px;
            display: block;
            transform: translate(3px, -3px);
        }
    }
}
