@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.payments-grand-total {
    &__value {
        display: block;
        font-size: 47px;
        font-weight: 200;
        color: getColor($secondary, 900);
        margin-top: $spacer / 3;
        margin-bottom: $spacer / 3;
    }
}
