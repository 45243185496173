@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.stats-kpi {
    display: flex;
    align-items: stretch;

    &__big-box {
        flex: 2;
        border-left: 5px solid getColor($registration, 600);
        padding: $spacer / 4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__delta-box {
        border-top: 1px solid getColor($registration, 600);
        color: getColor($registration, 600);
        font-size: 20px;
        width: 80px;
        display: flex;
        align-items: flex-start;
        padding-top: $spacer / 2;
        padding-right: $spacer / 2;
        padding-left: $spacer / 4;
        position: relative;

        &--default {
            background-color: getColor($registration, 600);
        }

        &--danger {
            background-color: getColor($secondary, 900);
        }

        &--warning {
            background-color: getColor($registration, 600);
        }

        &--success {
            background-color: getColor($registration, 600);
        }

        &::after {
            content: "";
            background-color: getColor($registration, 600);
            width: 1px;
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
        }
    }

    &__value {
        font-size: 28px;
        font-weight: 200;
        color: getColor($secondary, 900);
        margin-bottom: $spacer / 4;
    }

    &__description {
        font-size: 16px;
        color: getColor($registration, 600);
    }

    &__delta-icon {
        font-size: 14px;
        margin-right: 5px;
        transform: translateY(3px);
    }
}
