@import "src/assets/scss/variables";

.dashboard-page {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        "header header header"
        "col1 col2 col3";
    grid-row-gap: $spacer;
    grid-column-gap: $spacer * 3;
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;

    &__header {
        grid-area: header;
    }

    &__col1 {
        grid-area: col1;
    }

    &__col2 {
        grid-area: col2;
    }

    &__col3 {
        grid-area: col3;
    }
}
