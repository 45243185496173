@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.form-field {
    $ff: &;
    text-align: left;
    margin-bottom: $spacer / 2;

    &__is-invalid {
        border-color: red;
    }

    &__label {
        display: block;
        color: getColor($registration, 600);
        margin-bottom: $spacer / 6;
    }

    &__control {
        width: 100%;
        padding: $spacer / 4;
        border: 1px getColor($registration, 600);
        border-style: none none solid;
        font-size: 16px;
        margin-bottom: $spacer / 2;

        &:focus {
            outline: none;
            border-color: getColor($secondary, 900);
        }
    }

    &__error {
        font-size: 14px;
        color: red;
    }
}
