@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.stats-card {
    margin-bottom: $spacer;

    &__header {
        font-size: 23px;
        color: getColor($registration, 600);
        padding-bottom: $spacer / 2;
    }

    &__contents {
        display: flex;
        align-items: stretch;
    }

    &__big-box {
        flex: 2;
    }

    &__delta-box {
        color: getColor($secondary, 900);
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__value {
        font-size: 34px;
        font-weight: 200;
        color: getColor($secondary, 900);
    }

    &__description {
        padding-top: $spacer / 2;
        font-size: 14px;
        color: getColor($registration, 600);
    }
}
