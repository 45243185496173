@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700i&display=swap');

body {
    margin: 0;
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/helvetica-webfont.woff) format('woff');
}

@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 200;
    src: url(../fonts/helvetica-light-webfont.woff) format('woff');
}

@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    src: url(../fonts/helveticabold-webfont.woff) format('woff');
}
