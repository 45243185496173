@import "src/assets/scss/variables";

.streamion-gradient-brand {
    display: block;
    margin-bottom: $spacer;

    img {
        display: block;
        margin: 0 auto;
    }
}
