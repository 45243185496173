@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.secondary-navigation {
    margin-top: $spacer;
    margin-bottom: $spacer;

    &__link {
        font-size: 17px;
        color: getColor($registration, 600);
        position: relative;
        padding-left: $spacer / 2;
        padding-right: $spacer / 2;

        &--is-active {
            &::after {
                content: "";
                height: 7px;
                position: absolute;
                left: $spacer / 2;
                right: $spacer / 2;
                bottom: -13px;
                background: getColor($registration, 600);
            }
        }
    }
}
